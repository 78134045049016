import React from 'react';

/**
 * Images/SVG
 */
import Background from '../images/error-404-radium.png';

/**
 * Components
 */
import SEO from '../components/seo/seo';
import Layout from '../components/layout/layout';
import Banner from '../components/default-banner/default-banner';
import Back from '../components/go-back/go-back'

export default ( props ) => { 

  const innerBannerData = {
    backgroundImg: { backgroundImage: `url(${Background}` },
    mainTitle: '404',
    mainText: 
      'Are you sure this is the page your looking for?'
  };

  return (
    <Layout>
      <SEO 
        title="404" 
        keywords={[ `digital`, `agency`, `technology`, `Radium`, `404` ]} 
      />
      <Banner {...innerBannerData}/>
      <Back />
    </Layout>
  )
};
