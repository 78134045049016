import React from 'react';
import { Link } from 'gatsby';

import Styles from './go-back.module.scss';

export default () => (

<section className={Styles.goBackSection}>
  <div className="container">
    <Link 
      to="/"
      className="button__content button--svg-animation"
      activeClassName="link--active"
    >
      Go Back?
    </Link>
  </div>
</section>
);